$(function(){

    $(".review-area").on("click", ".more", function(){

        // show
        if($(this).closest('.slider-box').find('.additional').hasClass("d-none")){
            $(".slider-box").css({height:'400px'});
        }else{
            // hide
            $(".slider-box").css({height:'250px'});
        }

        $(this).closest('.slider-box').find('.additional').toggleClass("d-none");
        
    });




    if($('#vidModal').length > 0){
        var vidModal = new bootstrap.Modal($('#vidModal'));
        $(".btn.vid-btn").on("click", function(){
            $('#vidModal').find(".embed-responsive-item").attr('src', $(this).data("src"));
            vidModal.show();
        });
        $("#vidModal .close").on("click", function(){
            vidModal.hide()
        });
        // When the modal is hidden, pause (actually stop) the video
        $('#vidModal').on('hidden.bs.modal', function (e) {
            var iframe = $(this).find(".embed-responsive-item")[0];
            iframe.src = ""; 
        });
    }
});


window.onload = setTimeout(function() {

    if($(".glide").length){

        var script3 = document.createElement("script");
        script3.type = "text/javascript";
        script3.src = "https://unpkg.com/@glidejs/glide@3.6.0/dist/glide.min.js";
        document.head.appendChild(script3);

        // Dynamically load the first stylesheet
        var link1 = document.createElement("link");
        link1.rel = "stylesheet";
        link1.href = "/assets/scss/slider/glide.core.css";
        document.head.appendChild(link1);

        // Dynamically load the second stylesheet
        var link2 = document.createElement("link");
        link2.rel = "stylesheet";
        link2.href = "/assets/scss/slider/glide.theme.css";
        document.head.appendChild(link2);


        setTimeout(function() {
            new Glide('.glide',{
                type: 'carousel',
                startAt: 0,
                perView: 3,
                gap: 40,
                breakpoints: {
                    992: {
                        perView: 2
                    },
                    767: {
                        perView: 1,
                        peek: 70,
                        gap: 20
                    },
                    575: {
                        perView: 1,
                        peek: 25,
                        gap: 10
                    }
                }
            })
                .mount()
        }, 500);            
    }

},3000)

/* Set the width of the side navigation to 500px */
function openCloseNav() {
    var nav = document.getElementById("mySidenav");
    if (nav.style.width === '500px') {
        nav.style.width = '0';
        document.getElementById("menu-btn").src="images/menu.webp";
    }
    else {
        nav.style.width = "500px";
        document.getElementById("menu-btn").src="images/cancel.webp";
    }
}

/* When the user clicks on the button, toggle between hiding and showing the dropdown content */

function toggle(el, hide = false) {
    if (hide) {
        el.style.display = 'none';
    }
    else {
        if (el.style.display === '' || el.style.display === 'none') {
            el.style.display = 'block';
        } else {
            el.style.display = 'none';
        }
    }
}

function LoginMenu() {
    toggle(document.getElementById('contactDropdown'), true);
    toggle(document.getElementById('menuDropdown'), true);
    toggle(document.getElementById('loginDropdown'));
    document.getElementById('loginDropdown').classList.add('show');
}

function ContactFunction() {
    toggle(document.getElementById('loginDropdown'), true);
    toggle(document.getElementById('menuDropdown'), true);
    toggle(document.getElementById('contactDropdown'));
    document.getElementById('contactDropdown').classList.add('show');
}

function MenuFunction() {
    toggle(document.getElementById('loginDropdown'), true);
    toggle(document.getElementById('contactDropdown'), true);
    toggle(document.getElementById('menuDropdown'));
    document.getElementById('menuDropdown').classList.add('show');
}

// Close the dropdown if the user clicks outside of it
window.onclick = function(event) {
    //console.log(event.target);
    if (!event.target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
                toggle(openDropdown, true);
                //$(openDropdown).toggle(false);
                openDropdown.classList.remove('show');
            }
        }
    }
}

function contactToggle(){
    $("#contactDropdown").toggle();
}

// document.addEventListener("DOMContentLoaded", function() {
//     var lazyloadImages;

//     if ("IntersectionObserver" in window) {
//         lazyloadImages = document.querySelectorAll(".lazy");
//         var imageObserver = new IntersectionObserver(function(entries, observer) {
//             entries.forEach(function(entry) {
//                 if (entry.isIntersecting) {
//                     var image = entry.target;
//                     image.classList.remove("lazy");
//                     imageObserver.unobserve(image);
//                 }
//             });
//         });

//         lazyloadImages.forEach(function(image) {
//             imageObserver.observe(image);
//         });
//     } else {
//         var lazyloadThrottleTimeout;
//         lazyloadImages = document.querySelectorAll(".lazy");

//         function lazyload () {
//             if(lazyloadThrottleTimeout) {
//                 clearTimeout(lazyloadThrottleTimeout);
//             }

//             lazyloadThrottleTimeout = setTimeout(function() {
//                 var scrollTop = window.pageYOffset;
//                 lazyloadImages.forEach(function(img) {
//                     if(img.offsetTop < (window.innerHeight + scrollTop)) {
//                         img.src = img.dataset.src;
//                         img.classList.remove('lazy');
//                     }
//                 });
//                 if(lazyloadImages.length == 0) {
//                     document.removeEventListener("scroll", lazyload);
//                     window.removeEventListener("resize", lazyload);
//                     window.removeEventListener("orientationChange", lazyload);
//                 }
//             }, 20);
//         }

//         document.addEventListener("scroll", lazyload);
//         window.addEventListener("resize", lazyload);
//         window.addEventListener("orientationChange", lazyload);
//     }
// })

setTimeout(function () {
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/5acf26b94b401e45400e9096/default';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
    })();
    Tawk_API.onChatEnded = function () {
        wc_chat_ypbib("Chat", "New Chat Session");
    };
    Tawk_API.onOfflineSubmit = function () {
        wc_chat_ypbib("Chat", "New Chat Session");
    };

    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.9/dist/cookieconsent.js";
    document.body.appendChild(script);

    window.onload = setTimeout(function() {

        // obtain cookieconsent plugin
        let cc = initCookieConsent();

        // run plugin with config object
        cc.run({
            autorun: true,
            current_lang: 'en',
            autoclear_cookies: true,
            page_scripts: true,
            cookie_expiration: 365,                 // default: 182 (days)

            gui_options: {
                consent_modal: {
                    layout: 'box',               // box/cloud/bar
                    position: 'bottom left',     // bottom/middle/top + left/right/center
                    transition: 'slide',           // zoom/slide
                    swap_buttons: false            // enable to invert buttons
                },
                settings_modal: {
                    layout: 'box',                 // box/bar
                    // position: 'left',           // left/right
                    transition: 'slide'            // zoom/slide
                }
            },

            onFirstAction: function(user_preferences, cookie){
                // callback triggered only once
                // [ajm] what is this
            },

            onAccept: function (cookie) {
                if(cc.allowedCategory('analytics')){
                    enableAnalytics();
                }

                if(cc.allowedCategory('ads')){
                    enableAds();
                }
            },

            onChange: function (cookie, changed_categories) {
                if(cc.allowedCategory('analytics')){
                    enableAnalytics();
                }else{
                    disableAnalytics();
                }

                if(cc.allowedCategory('ads')){
                    enableAds();
                }else{
                    disableAds();
                }
            },

            languages: {
                en: {
                    consent_modal: {
                        title: 'Cookies',
                        description: 'This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only upon approval. <a aria-label="Cookie policy" class="cc-link" href="cookie-policy">Read more</a>',
                        primary_btn: {
                            text: 'Accept',
                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Settings',
                            role: 'settings'                // 'settings' or 'accept_necessary'
                        }
                    },
                    settings_modal: {
                        title: 'Cookie preferences',
                        save_settings_btn: 'Save settings',
                        accept_all_btn: 'Accept all',
                        reject_all_btn: 'Reject all',       // optional, [v.2.5.0 +]
                        cookie_table_headers: [
                            {col1: 'Name'},
                            {col2: 'Domain'},
                            {col3: 'Expiration'},
                            {col4: 'Description'},
                            {col5: 'Type'}
                        ],
                        blocks: [
                            {
                                title: 'Cookie usage',
                                description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.'
                            }, {
                                title: 'Strictly necessary cookies',
                                description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true
                                }
                            }, {
                                title: 'Analytics cookies',
                                description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.',
                                toggle: {
                                    value: 'analytics',
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [
                                    {
                                        col1: '^_ga',
                                        col2: 'google.com',
                                        col3: '2 years',
                                        col4: 'description ...',
                                        col5: 'Permanent cookie',
                                        is_regex: true
                                    },
                                    {
                                        col1: '_gid',
                                        col2: 'google.com',
                                        col3: '1 day',
                                        col4: 'description ...',
                                        col5: 'Permanent cookie'
                                    }
                                ]
                            },
                            {
                                title: 'Advertising',
                                description: 'These cookies are used to track advertising effectiveness to provide a more relevant service and deliver better ads to suit your interests.',
                                toggle: {
                                    value: 'ads',
                                    enabled: false,
                                    readonly: false,
                                }
                            },
                            {
                                title: 'More information',
                                description: 'For any queries in relation to my policy on cookies and your choices, please <a class="cc-link" href="contact">contact us</a>.',
                            }
                        ]
                    }
                }
            }
            
        });
    }, 500);
}, 10000);

/* Helper functions to avoid repeating the same ga code */
function enableAnalytics(){
    //console.log("enabled analytics")
    gtag('consent', 'update', {
        'analytics_storage': 'granted'
    });
}

function disableAnalytics(){
    //console.log("disabled analytics")
    // Default ad_storage to 'denied'.
    gtag('consent', 'default', {
        'analytics_storage': 'denied'
    });
}

function enableAds(){
    //console.log("enabled ads")
    gtag('consent', 'update', {
        'ad_storage': 'granted'
    });
}

function disableAds(){
    //console.log("disabled ads")
    gtag('consent', 'update', {
        'ad_storage': 'denied'
    });
}


// Function to check if user has given consent for a specific category
function userHasGivenConsent(category) {
    var name = "cc_cookie=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            var cookieValue = c.substring(name.length, c.length);
            var consentData = JSON.parse(cookieValue);
            return consentData.categories.includes(category);
        }
    }
    return false;
}

gtag('consent', 'default', {
    'ad_storage': 'denied',
    'analytics_storage': 'denied'
});

// Check user consent and enable appropriate features
if (userHasGivenConsent('analytics')) {
    enableAnalytics();
}

if (userHasGivenConsent('ads')) {
    enableAds();
}

// (function() {
//     var button = document.querySelector('#show');
//     var box = document.querySelector('.link-box');
//     button.addEventListener('click', function() {
//         box.classList.toggle('active');
//     });
// })();
document.getElementById('navicon').onclick = function() {
    this.classList.toggle('open');
}


$(function(){
    $(".foll-area #show").on("click", function(){
        $(".foll-area").toggleClass('active');
        if($(".foll-area").hasClass('active')){
            $(".foll-area .show-text").html("Show less");
        }else{
            $(".foll-area .show-text").html("Show more");
        }
    });


    $(".password-eye").on("click", function(){
        var $input = $(this).parent("div").find("input");
        if ($input.attr("type") === "password") {
            $input.attr("type", "text");
        } else {
            $input.attr("type", "password");
        }
    });

});
